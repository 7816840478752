<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          EAMS UI
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to EAMS! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginValidation"
            #default="{ handleSubmit }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="handleSubmit(onSubmitLogin)"
            >
              <!-- username -->
              <b-form-group
                label="Username"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="User"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password.text"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="password.fieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password.toggleIcon"
                        @click="passwordToggleIcon"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click.prevent="onSubmitLogin"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BFormGroup,
  BLink,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import store from '@/store/index'
import EcLogo from '@/layouts/components/Logo.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    EcLogo,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required
    }
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm
    } = formValidation()

    const ctx = getCurrentInstance()
    const toast = useToast()
    const imgUrl = ref('')
    const username = ref('')
    const password = ref({
      fieldType: 'password',
      toggleIcon: 'EyeOffIcon',
      text: ''
    })

    onMounted(() => {
      const isDark = store.state.appConfig.layout.skin === 'dark'
      imgUrl.value = isDark ? 
        require('@/assets/images/pages/login-v2-dark.svg') :
        require('@/assets/images/pages/login-v2-dark.svg')
    })
   
    const passwordToggleIcon = () => {
      const closed = password.value.fieldType === 'password'
      if (closed) {
        password.value.fieldType = 'text'
        password.value.toggleIcon = 'EyeIcon'
      } else {
        password.value.fieldType = 'password'
        password.value.toggleIcon = 'EyeOffIcon'
      }
    }

    const onSubmitLogin = async () => {
      // console.log('Submited')
      // console.log({password, username, imgUrl})
      try {
        await store.dispatch('auth/authenticateUser', {
          username: username.value,
          password: password.value.text
        })

        toast({
          component: ToastificationContent,
          props: {
            title: 'Successfuly signed in',
            icon: 'EditIcon',
            variant: 'success'
          }
        })
        ctx.proxy.$router.push('/')
      } catch(error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }
    }
    
    return {
      password,
      username,
      imgUrl,
      required,
      onSubmitLogin,
      refFormObserver,
      getValidationState,
      passwordToggleIcon
    }
  }
}

  /*
  data() {
    return {
      disableAllInputs: false,
      protectedUI: false,
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {

          // Remove alert boxes
          this.message = null
          this.protectedUI = true

          try {
            await store.dispatch('auth/authenticateUser', {
              username: this.username,
              password: this.password
            })
            this.disableAllInputs = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successfuly signed in',
                icon: 'EditIcon',
                variant: 'success'
              }
            })

            this.password = ''
            this.$router.push('/')

          } catch (error) {
            this.protectedUI = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
          }
        }      
      })
    }
  }
  */
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
</style>
